<template>
  <div
    class="return-method-container"
  >
    <base-text
      v-if="shouldShowLabelChoices"
      type="body-2"
      class="return-method-prompt"
    >
      {{ content.prompt }}
    </base-text>
    <base-text
      type="body-2"
      class="return-method-legal"
    >
      {{ content.body }}
    </base-text>

    <base-button
      v-track="track"
      class="all-locations-btn"
      size="small"
      type="secondary"
      target="_blank"
      :to="locationsLink"
      @click.stop
    >
      <span
        class="icon-label"
      >
        {{ content.viewLocationsBtn }}
        <base-icon
          class="external-icon"
          name="external-link"
        />
      </span>
    </base-button>

    <div v-if="shouldShowLabelChoices">
      <hr class="label-choice__hr" />

      <div class="label-choice__radio-container">
        <div
          v-for="option in returnOptions"
          :key="option.key"
          class="label-choice__radio"
          @click="handlePickupOptionSelect(option.key)"
        >
          <radio-input
            v-model="returnOptionSelected"
            class="label-choice__radio-option"
            :class="{ disabled : !returnMethod.options.includes(option.key) }"
            :value="option.key"
            :disabled="!returnMethod.options.includes(option.key)"
          >
            <base-text
              class="label-choice__radio-option-heading"
              type="body"
              data-testid="qr-code-radio-button"
            >
              {{ option.heading }}
            </base-text>
            <base-text
              class="label-choice__radio-option-copy"
              type="body-2"
            >
              {{ option.copy }}
            </base-text>
          </radio-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseText,
  BaseIcon,
  RadioInput,
} from '@loophq/design-system';
import firstMile from '@/js/constants/firstMile';

const returnMethodMappings = {
  [firstMile.INPOST]: {
    url: 'https://inpost.co.uk/lockers/?utm_source=Loop&utm_medium=returns_portal&utm_campaign=instant_returns',
    track: 'inpost view locations clicked',
    content: 'moduleReturnMethodSelectorInpost',
  },
  [firstMile.ASDA]: {
    url: 'https://storelocator.asda.com',
    track: 'asda view locations clicked',
    content: 'moduleReturnMethodSelectorAsda',
  },
  [firstMile.EVRI]: {
    url: 'https://www.evri.com/find-a-parcelshop',
    track: 'evri view locations clicked',
    content: 'moduleReturnMethodSelectorEvri',
  },
  [firstMile.SEKO]: {
    url: 'https://tools.usps.com/find-location.htm?locationType=po&serviceType=lbroretail',
    track: 'seko usps view locations clicked',
    content: 'moduleReturnMethodSelectorSeko',
  },
  [firstMile.PITNEY_BOWES_STANDARD]: {
    url: 'https://tools.usps.com/find-location.htm?locationType=po&serviceType=lbroretail',
    track: 'pitney bowes standard view locations clicked',
    content: 'moduleReturnMethodSelectorPitneyBowesStandard',
  },
  [firstMile.COLISSIMO_RN]: {
    url: 'https://www.laposte.fr/particulier/outils/trouver-un-bureau-de-poste',
    track: 'colissimo rn view locations clicked',
    content: 'moduleReturnMethodSelectorColissimoRn',
  },
  [firstMile.COLISSIMO_RK]: {
    url: 'https://www.laposte.fr/particulier/outils/trouver-un-bureau-de-poste',
    track: 'colissimo rk view locations clicked',
    content: 'moduleReturnMethodSelectorColissimoRk',
  },
  [firstMile.DPD_UK]: {
    url: 'https://www.dpd.co.uk/apps/shopfinder/index.jsp',
    track: 'dpd uk view locations clicked',
    content: 'moduleReturnMethodSelectorDpdUk',
  },
  [firstMile.DHL_CONNECT_DE]: {
    url: 'https://www.dhl.de/de/privatkunden.html',
    track: 'dhl connect de view locations clicked',
    content: 'moduleReturnMethodSelectorDhlConnectDe',
  },
  [firstMile.DHL_RETOURE_DE]: {
    url: 'https://www.dhl.de/de/privatkunden.html',
    track: 'dhl retoure de view locations clicked',
    content: 'moduleReturnMethodSelectorDhlRetoureDe',
  },
  [firstMile.ROYAL_MAIL_REBOUND]: {
    url: 'https://www.royalmail.com/services-near-you',
    track: 'royal mail view locations clicked',
    content: 'moduleReturnMethodSelectorRoyalMail',
  },
  [firstMile.UPS_MI]: {
    url: 'https://www.ups.com/dropoff',
    track: 'ups mail innovations view locations clicked',
    content: 'moduleReturnMethodSelectorUpsMi',
  },
  [firstMile.POSTNORD_DK]: {
    url: 'https://www.postnord.se/vara-verktyg/sok-brevlada-eller-servicestalle/#0.43/0/0',
    track: 'postnord dk view locations clicked',
    content: 'moduleReturnMethodSelectorPostnordDk',
  },
  [firstMile.HERMES]: {
    url: 'https://www.myhermes.de/paketshop/',
    track: 'hermes view locations clicked',
    content: 'moduleReturnMethodSelectorHermes',
  },
};

export default {
  components: {
    BaseButton,
    BaseText,
    BaseIcon,
    RadioInput,
  },
  props: {
    returnMethod: {
      type: Object,
      required: true,
    },
  },
  emits: ['validated'],
  data() {
    return {
      expanded: false,
      returnOptions: [],
      returnOptionSelected: '',
    };
  },
  computed: {
    shouldShowLabelChoices() {
      return firstMile.RETURN_METHODS_WITH_LABEL_CHOICES.includes(this.returnMethod.name);
    },
    locationsLink() {
      return returnMethodMappings[this.returnMethod.name]?.url || '';
    },
    track() {
      return returnMethodMappings[this.returnMethod.name]?.track || '';
    },
    content() {
      return this.$content[returnMethodMappings[this.returnMethod.name]?.content] || {};
    }
  },
  created() {
    // Some carriers require a customer selection between QR code and Label - see firstMile.LABEL_CHOICES
    if (this.shouldShowLabelChoices) {
      const labelChoices = firstMile.LABEL_CHOICES[this.returnMethod.name];

      if (labelChoices) {
        this.returnOptions = [
          {
            key: labelChoices.qrCode,
            heading: this.content.qrCodeHeading,
            copy: this.content.qrCodeCopy,
          },
          {
            key: labelChoices.label,
            heading: this.content.labelHeading,
            copy: this.content.labelCopy,
          },
        ];

        // If only one return option is available, preselect the option
        if (this.returnMethod?.options?.length === 1) {
          const key = this.returnMethod.options[0];
          this.returnOptionSelected = key;
          this.handlePickupOptionSelect(key);
          this.$emit('validated', true);
        }
      }
    }
  },
  methods: {
    handlePickupOptionSelect(key) {
      if (this.returnMethod.options.includes(key)) {
        this.$store.dispatch('firstMile/setReturnMethod', {
          ...this.returnMethod,
          returnOption: key,
        });
        this.$emit('validated', true);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.all-locations-btn {
  margin-top: 0;
}

.distance {
  margin-bottom: 12px;
}

.heading {
  display: inline-block;
  color: var(--grey-800);
  font-weight: 500;
  padding-right: var(--spacing-200);
}

.link {
  color: inherit !important;
  font-weight: normal;
  display: inline-block !important;
}

.location-info {
  margin: var(--spacing-300) 0;
}

.show-more {
  margin: var(--spacing-400) 0 0;
  display: flex !important;
}

.show-more-label {
  display: flex;
  color: var(--primary-color);
}

.location-address {
  text-decoration: underline;
  text-align: left;
}

.return-method-container {
  padding: var(--spacing-400);
}

.external-icon {
  height: 16px;
  margin-left: var(--spacing-200);
}

.icon-label {
  display: flex;
  align-items: center;
  color: #41454a;
}

.link .icon-label {
  text-decoration: underline;
}

.link-icon {
  height: 12px;
}

.return-method-legal {
  color: var(--grey-800);
  margin-bottom: 12px;
}

.label-choice__hr {
  border: 1px solid var(--grey-200);
  margin: var(--spacing-400) 0;
}

.label-choice__radio {
  & + & {
    margin-top: var(--spacing-200);
  }

  &-option {
    display: flex;
    align-items: center;
    padding: var(--spacing-200) 0;

    &-heading {
      color: var(--heading-color);
      font-weight: 600;
    }

    &.disabled {
      &:deep(.radio-input__input) {
        border: none;
        background-color: var(--grey-300);
      }

      &:deep(.radio-input__label) > p {
        color: var(--grey-600);
      }
    }
  }

  &:deep(.radio-input__label) {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 var(--spacing-300);
  }
}

@media screen and (max-width: $break-small) {
  .distance {
    display: block;
  }
}
</style>

import '@/scss/main.scss';
import '/node_modules/@loophq/design-system/dist/design-system.css';

//Primary Dependencies
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
//Secondary Dependencies
import $axios from 'axios';
import 'focus-visible';
import { GlobalEvents } from 'vue-global-events';
import { setMobileVH, observeWindowChanges } from '@/js/helpers';
import handleOffline from '@/js/offline';
import useStoreMethods from '@/js/mixins/store';
const { getStore } = useStoreMethods();
import initData from '@/js/initData';
import { makeServer } from './server';
import { watchMessages } from '@/js/messages';
import { fixRoutes } from '@/js/helpers/fixRoutesWithQueryParams';
import validation from '@/js/validation';
import { Field, Form } from 'vee-validate';
//components
import AppHeader from '@/components/globals/AppHeader';
import AppFooter from '@/components/globals/AppFooter';
import CreditOption from '@/components/globals/CreditOption';
import ErrorModule from '@/components/globals/ErrorModule';
import Popup from '@/components/globals/Popup';
import TransitionExpand from '@/components/globals/TransitionExpand';
import RenderContent from '@/components/renderers/RenderContent.vue';

//filters
import { limit, currency } from '@/js/filters';
import env from '@/env';
//Directives
import directives from '@/js/directives';
//Other
import { datadogRum } from '@datadog/browser-rum';

//Create App
const app = createApp(App);

//Register Vuex
app.use(store);

//Register Router
app.use(router);

//Register Directives
Object.keys(directives).forEach(key => {
  app.directive(key, directives[key]);
});

if (env('DEV') && env('VITE_MIRAGE_ENABLED')) {
  makeServer();
}

// Fix requests with query params
if (window.location.search) {
  fixRoutes();
}

// Global getters
import Globals from '@/js/globals';

// Etc
import '@/js/filters';
import '@/js/directives';

handleOffline((isOnline) => {
  store.commit('updateData', {
    name: 'online',
    data: isOnline
  });
});

//Config
app.config.debug = env('VITE_DEBUG_ON');

$axios.defaults.baseURL = env('VITE_API_URL');

//Datadog RUM Initilization
if (
  env('VITE_DATADOG_APPLICATION_ID_CUSTOMER_PORTAL')
  && env('VITE_DATADOG_CLIENT_TOKEN_CUSTOMER_PORTAL')
  && env('VITE_DATADOG_SAMPLE_RATE_CUSTOMER_PORTAL')
) {
  datadogRum.init({
    applicationId: env('VITE_DATADOG_APPLICATION_ID_CUSTOMER_PORTAL'),
    clientToken: env('VITE_DATADOG_CLIENT_TOKEN_CUSTOMER_PORTAL'),
    site: 'datadoghq.com',
    service: 'customer-portal',
    env: env('VITE_DATADOG_ENV'),
    version: env('VITE_DATADOG_VERSION'),
    sessionSampleRate: env('VITE_DATADOG_SAMPLE_RATE_CUSTOMER_PORTAL'),
    trackUserInteractions: true,
    trackFrustrations: true,
    sessionReplaySampleRate: env('VITE_DATADOG_SESSION_REPLAY_RATE_CUSTOMER_PORTAL'),
    trackViewsManually: true,
    //allow all origins
    allowedTracingOrigins: ['*'],
  });
  datadogRum.startSessionReplayRecording();
}

app.config.globalProperties.$axios = $axios;
app.mixin(Globals);

validation();

app.component('GlobalEvents', GlobalEvents);

// Register validation components
// eslint-disable-next-line vue/no-reserved-component-names
app.component('Form', Form);
app.component('Field', Field);

// Vue Components Outside of Router
// registerGlobalComponents(app);
app.component('AppHeader', AppHeader);
app.component('AppFooter', AppFooter);
app.component('CreditOption', CreditOption);
app.component('ErrorModule', ErrorModule);
app.component('Popup', Popup);
app.component('TransitionExpand', TransitionExpand);
app.component('RenderContent', RenderContent);

//Temp Solution for Global Filters
app.config.globalProperties.$filters = {
  limit,
  currency,
};

setMobileVH();
observeWindowChanges();

// Init from localstorage if available
const data = getStore('init', '');

// When a user visits the tracking page, we want to avoid loading customizations that may exist in their local storage for a shop they previously visited.
const isTracking = window.location.href.includes('/tracking/');

if (!isTracking && data !== undefined && router.currentRoute && !(env('NODE_ENV') === 'development' && env('VITE_MIRAGE_ENABLED'))) {
  initData(router.currentRoute, data);
}

//mount app
app.mount('#app');

if (window.Cypress) {
  window.app = app;
}

watchMessages();

export default app;
